import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Messenger from "../../../utilities/messenger";
import Lang from "../../LanguageSelect/Lang";

const LoggerCommandModal = (props) => {
  const [commandHistory, setCommandHistory] = useState([]);

  useEffect(() => {
    if (props.systemConfigShowLoggerCommandModal) {
      fetchCommandHistory();
    }
  }, [props.systemConfigShowLoggerCommandModal]);

  const closeModal = () => {
    const { setValues } = props;
    setValues({
      systemConfigShowLoggerCommandModal: false,
      systemConfigCommandLoggerId: "",
      systemConfigCommandLoggerName: "",
      systemConfigLoggerCommand: "",
    });
    setCommandHistory([]);
  };

  const handleLoggerCommand = () => {
    const {
      systemConfigCommandLoggerId,
      customerId,
      systemsSelectedSystem,
      systemConfigCommandLoggerName,
      systemConfigLoggerCommand,
      setValues,
      restartPolling,
    } = props;

    if (systemConfigLoggerCommand.trim() === "") {
      toast.warning(<Lang fi="Syötä komento" />);
      return;
    }

    if (systemConfigCommandLoggerId.length === 8) {
      const payload = {
        customerId,
        systemId: systemsSelectedSystem.id,
        loggerId: parseInt(systemConfigCommandLoggerId, 16),
        loggerName:
          systemConfigCommandLoggerName !== undefined &&
          systemConfigCommandLoggerName !== ""
            ? systemConfigCommandLoggerName
            : `Node_${systemConfigCommandLoggerId}`,
        command: systemConfigLoggerCommand,
      };

      const callback = (data) => {
        if (data.state === "ok") {
          setValues({
            systemConfigCommandLoggerId: "",
            systemConfigCommandLoggerName: "",
          });
          restartPolling();
        }
      };

      closeModal();
      toast.success(<Lang fi="Komento lähetetty" />);
      Messenger.sendNodeCommand({ payload, callback: callback.bind(this) });
    } else {
      toast.warning(<Lang fi="Komennon lähetys epäonnistui" />);
    }
  };

  const fetchCommandHistory = () => {
    const { systemConfigCommandLoggerId, customerId, systemsSelectedSystem } =
      props;

    if (systemConfigCommandLoggerId.length === 8) {
      const payload = {
        customerId,
        systemId: systemsSelectedSystem.id,
        loggerId: parseInt(systemConfigCommandLoggerId, 16),
      };

      const callback = (data) => {
        if (data.status === "success" && Array.isArray(data.command_response)) {
          // Update the local state with the command response array
          setCommandHistory(data.command_response);
        } else {
          console.error("Failed to fetch command history");
        }
      };

      Messenger.requestNodeCommandHistory({ payload, callback });
    }
  };

  const renderCommandHistory = () => {
    if (!Array.isArray(commandHistory) || commandHistory.length === 0) {
      return <p>No command history available.</p>;
    }

    return (
      <table className="command-history-table">
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>NID</th>
            <th>Command</th>
            <th>Response</th>
          </tr>
        </thead>
        <tbody>
          {commandHistory.map((item, index) => (
            <tr key={index}>
              <td>{new Date(item.timestamp * 1000).toLocaleString()}</td>
              <td>{item.nid}</td>
              <td>{item.command}</td>
              <td>{item.response}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const {
    systemConfigShowLoggerCommandModal,
    systemConfigCommandLoggerId,
    setValues,
    systemConfigCommandLoggerName,
    systemConfigLoggerCommand,
  } = props;

  return (
    <Modal
      key="commandloggermodal"
      className="command-logger-modal"
      show={systemConfigShowLoggerCommandModal}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Lang
            fi={`Komento loggerille: ${systemConfigCommandLoggerName} (${systemConfigCommandLoggerId})`}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          <Lang fi="Komento" />
          <Form.Control
            className="logger-command-input"
            type="text"
            value={systemConfigLoggerCommand}
            onChange={(e) => setValues({ systemConfigLoggerCommand: e.target.value })}
          />
        </Form.Label>
        <h5>Historia</h5>
        {renderCommandHistory()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleLoggerCommand}>
          <Lang fi="Lähetä" />
        </Button>
        <Button variant="danger" onClick={closeModal}>
          <Lang en="Cancel" fi="Peruuta" sv="Ångra" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  const {
    customerId,
    systemConfigShowLoggerCommandModal,
    systemConfigCommandLoggerId,
    systemConfigCommandLoggerName,
    systemsSelectedSystem,
    systemConfigLoggerCommand,
  } = state;
  return {
    customerId,
    systemConfigShowLoggerCommandModal,
    systemConfigCommandLoggerId,
    systemConfigCommandLoggerName,
    systemsSelectedSystem,
    systemConfigLoggerCommand,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggerCommandModal);
